<template>
  <secondary-page-layout title="SOS" :displayedNavigation="true">
    <div class="px-3">
      <template v-if="!navLoading">
        <div class="primary-block primary-nav-list">
          <h3 class="title">{{$t('sosTitle')}}</h3>
          <router-link :to="{name:'sos.instruction', params: {id: item.id}}" class="nav-item" v-for="(item) in insuranceCases" :key="item.id">
            <div class="icon-box"><img :src="item.icon"/></div>
            <p class="ms-3 mb-0">{{ item.title }}</p>
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="primary-block primary-nav-list">
          <div class="nav-item border-bottom-0" v-for="(item, index) in 5" :key="index">
            <div class="icon-box skeleton"></div>
            <p class="skeleton ms-3 skeleton-text"></p>
          </div>
        </div>
      </template>
    </div>
  </secondary-page-layout>
</template>

<script>
export default {
  name: "SosNavigation",
  data() {
    return {
      insuranceCases: [],
      navLoading: true,
    }
  },
  mounted() {
    this.getInsuranceCases();
  },
  methods: {
    async getInsuranceCases() {
      const {data} = await window.axios.get('/insurance-cases');
      this.insuranceCases = data;
      this.navLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
